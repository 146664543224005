import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  App,
  Config,
  CreatePassword,
  Login,
  NotFound,
  Welcome,
} from '../pages';
import { OnboardingSteps } from '../templates';
import { Onboarding } from '../templates/Onboarding';
import PrivateRoute from './privateRoutes';
import ResetPassword from '../pages/ResetPassword';

const Routes = () => (
  <Router>
    <Switch>
      <Route component={Login} path="/" exact />
      <Route component={CreatePassword} path="/create-password" exact />
      <Route component={ResetPassword} path="/reset-password" exact />
      <PrivateRoute component={Onboarding} path="/pre-onboarding" />
      <PrivateRoute path="/welcome" component={Welcome} exact />
      <PrivateRoute path="/app/:dashId" component={App} exact />
      <PrivateRoute path="/app/:dashId/config" component={Config} exact />
      <PrivateRoute path="/onboarding" component={OnboardingSteps} exact />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;

import axios from 'axios';
import { BASE_URL } from './constants';

type Headers = {
  'Content-Type'?: string;
};

const getAuthHeaders = async (headersParam: any) => {
  const headers = headersParam;
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  headers.Authorization = `Bearer ${accessToken}`;
};

export const API = async ({
  auth = false,
  refresh = true,
  formData = false,
  revoking = false,
}) => {
  const headers: Headers = {};

  if (formData) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  if (auth) {
    await getAuthHeaders(headers);
  }

  const instance = axios.create({
    baseURL: BASE_URL || 'https://api.wedash.digital',
    headers,
  });

  if (refresh) {
    instance.interceptors.response.use(
      response => response,
      async error => {
        if (error?.response?.status === 401) {
          const { refreshToken } = await import('./auth');
          await refreshToken();
          const api = await API({ refresh: false });
          const newHeaders = { ...error.config.headers };
          await getAuthHeaders(newHeaders);
          const newRequest = {
            ...error.config,
            headers: newHeaders,
          };
          const result = await api(newRequest);

          return result;
        }
        throw error;
      },
    );
  } else if (!revoking) {
    instance.interceptors.response.use(
      response => response,
      async error => {
        if (error?.response?.status === 401) {
          const { logout } = await import('./auth');
          await logout();
        }
        throw error;
      },
    );
  }

  return instance;
};

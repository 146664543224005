import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const FormTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin: 3rem 0;
`;

export const PasswordRequirements = styled(Typography)`
  font-size: 1.7rem;
  font-weight: 500;
`;

export const Img = styled.img`
  margin-right: 1rem;
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    margin-top: 1rem;
    font-size: 1.2rem;
    text-align: center;
    color: ${theme.colors.red};
  `}
`;

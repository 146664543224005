import * as yup from 'yup';

const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.;:|,]).{8,}$/,
      'A senha não atende os requisitos mínimos',
    )
    .required('Campo obrigatório'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas divergentes')
    .required('Campo obrigatório'),
});

export default ResetPasswordSchema;
